import React from 'react';
import { Container } from 'react-bootstrap';
import { connect, ConnectedProps } from "react-redux";
import { LoginState, User } from '../_constants/auth-types';
import { login } from "../_actions/auth-actions";
import { Redirect } from 'react-router-dom';

// import './login.css'; // Tell webpack that Button.js uses these styles

type Props = PropsFromRedux & {
    user: User,
    logged: boolean,
    loading: boolean,
    error: Error
}

const mapStateToProps = (state: LoginState) => {
    return {
        user: state.auth.user,
        logged: state.auth.logged,
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const connector = connect(mapStateToProps, { login });

type PropsFromRedux = ConnectedProps<typeof connector>

interface LoginInternalState extends Record<string,any> {
    username: string,
    password: string,
    submitted: boolean,
}

class LoginPage extends React.Component<Props, LoginInternalState> {
    constructor(props: Props) {
        super(props);

        // reset login status
        //this.props.dispatch(userActions.logout());

        this.state = {
            username: '',
            password: '',
            submitted: false
        };        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e: any) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e: any) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if (username && password) {
            this.props.login(username, password);
        }
    }

    render() {
        const { loading, error } = this.props;
        const { username, password, submitted } = this.state;        
        return (            
        <>
            {
                error?.toString() === "Password Expired" ? 
                <Redirect to='/changepassword'/>
                : 
                <Container role="main-login" className="main-login-container">
                    <h1 className="text-center">Sms Staging Area</h1>
                    { /*  <span>logged: {user ? user.username : null }</span> */}
                    <form className="form-signin" onSubmit={this.handleSubmit}>
                        <div className="text-center mb-4">
                            <h2>Login</h2>
                        </div>
                        { /*- <Alert/>  */}
                        {error &&
                            <div style={{fontSize: "16px", color: "red", textAlign: "center"}} className="help-block">{error}</div>
                        }
                        <div className={'form-label-group form-group' + (submitted && !username ? ' has-error' : '')}>
                            <input type="text" className="form-control" name="username" value={username}
                                onChange={this.handleChange} placeholder="Username" required autoFocus />
                            {submitted && !username &&
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">Username obbligatorio</div>
                            }
                        </div>
                        <div className={'form-label-group form-group' + (submitted && !password ? ' has-error' : '')}>
                            <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} required placeholder="Password" />
                            {submitted && !password &&
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">Password obbligatoria</div>
                            }
                        </div>
                        <div className="form-label-group form-group">
                            <button className="btn btn-lg btn-primary btn-block">Sign in</button>
                            {loading &&
                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </div>

                    </form>
                </Container>
            }
            </>
        );
    }
}

const connLoginPaget = connector(LoginPage);
export { connLoginPaget as LoginPage };


