import {
  FilteredCountModel,
  FilteredCountActionTypes,
  FETCH_FILTERED_COUNT_REQUEST,
  FETCH_FILTERED_COUNT_SUCCESS,
  FETCH_FILTERED_COUNT_FAILURE
} from '../_constants/filtered.count-types';

export type FilteredCountState = { loading: boolean } | { items: FilteredCountModel[] } | { error: Error } | any;

const initialState: FilteredCountState = {
  items: [],
  loading: false
}

export function filteredCounts(state = initialState, action: FilteredCountActionTypes): FilteredCountState {
  switch (action.type) {
    case FETCH_FILTERED_COUNT_REQUEST:
      return {
        loading: true,
        item: []
      }
    case FETCH_FILTERED_COUNT_SUCCESS:
      return Object.assign({}, {
        loading: false,
        //item: state.item.concat(action.payload)
        item: action.payload
      })
    case FETCH_FILTERED_COUNT_FAILURE:
        return Object.assign({}, {
          loading: false,
          error: action.payload
        });

    default:
      return state
  }
}

