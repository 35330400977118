
import { Dispatch } from "react";
import { FilteredCountActionTypes, FETCH_FILTERED_COUNT_REQUEST, FilteredCountModel, FETCH_FILTERED_COUNT_SUCCESS, FETCH_FILTERED_COUNT_FAILURE, SmsQueryModel } from "../_constants/filtered.count-types";
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

//https://redux.js.org/recipes/usage-with-typescript


export const filteredCountActions = {
  list
};

// TypeScript infers that this function is returning FetchFilteredCountRequestAction
function fetchFilteredCountRequest(): FilteredCountActionTypes {
  return {
    type: FETCH_FILTERED_COUNT_REQUEST
  }
}

function fetchFilteredCountSuccess(item: FilteredCountModel): FilteredCountActionTypes {
  return {
    type: FETCH_FILTERED_COUNT_SUCCESS,
    payload: item
  }
}

function fetchFilteredCountFailure(error: Error): FilteredCountActionTypes {
  return {
    type: FETCH_FILTERED_COUNT_FAILURE,
    payload: error.message
  }
}

const config = {
  apiUrl: process.env.REACT_APP_API_URL
}
const autenticationTokenStorageService = new AutenticationTokenStorageService();

export function list(query: SmsQueryModel) {
  console.log("query", query);
  console.log("JSON.stringify(query)", JSON.stringify(query));
  
  return function (dispatch: Dispatch<any>) {
    dispatch(fetchFilteredCountRequest());
    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(query)
      //body: query
    };

    return fetch(`${config.apiUrl}/api/kpi/filteredCount`, requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(`Unauthorized`);
          } else {
            throw new Error(`${response.status} ${response.statusText}`);
          }
        }
        return response;
      }
      )
      .then(response => response.json())
      .then(json => {
        console.log("json", json);
        dispatch(fetchFilteredCountSuccess(json));
      })
      .catch((error: Error) => {
        dispatch(fetchFilteredCountFailure(error));
        autenticationTokenStorageService.logoutIfNotAuthorized(error);
      })
  };  

}

