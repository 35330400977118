
import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';

import logger from 'redux-logger'
import { auth } from '../_reducers/auth.reducer';
import { alert } from '../_reducers/alert.reducer';
import { kpis } from '../_reducers/kpi.reducer';
import { filteredCounts } from '../_reducers/filtered.count.reducer';
import { exportStates } from '../_reducers/filtered.export.reducer';

const rootReducer = combineReducers({  
  auth,
  alert,
  kpis,
  filteredCounts,
  exportStates
});


export default function configureAppStore(preloadedState: any) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [ /*loggerMiddleware,*/ ...getDefaultMiddleware(), logger], //logger must be the last
    preloadedState,
    enhancers: [ /*monitorReducersEnhancer*/]
  })

  /*if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../_reducers/article-reducers', () => store.replaceReducer(rootReducer))
  } */ //TODO in ts

  return store
}

export type RootState = ReturnType<typeof rootReducer>