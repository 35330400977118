//import config from 'config';
import { AutenticationTokenStorageService } from './autentication.token.storage.service';
import { AnoamlyEventModel } from '../_constants/anomaly-event-types';
import { BatchFlow } from '../_constants';

export const AnomalyEventService = {
    getAll,
};

const config = {
    apiUrl: process.env.REACT_APP_API_URL
}

const autenticationTokenStorageService = new AutenticationTokenStorageService();

async function getAll(batchFlow: BatchFlow): Promise<AnoamlyEventModel[]> {
    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: requestHeaders
    };

   // console.log("requestOptions", requestOptions);

    const response = await fetch(`${config.apiUrl}/api/anomalyEvents?batchFlow=${batchFlow}`, requestOptions);

    return response.json();
}
