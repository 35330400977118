import React from 'react';
import { Kpi } from '../_components/Kpi';
import { BarNav } from '../_components/BarNav';
import { Accordion, Card } from "react-bootstrap";

import { FilteredCount } from '../_components/FilteredCount';

import { Container } from 'react-bootstrap';

import { ExportDump } from '../_components/ExportDump';
import { ExportInstaller } from '../_components/ExportInstaller';
import { ExportReminder } from '../_components/ExportReminder';

class HomePageComp extends React.Component {

  render() {
    return (
      <>
        <BarNav />
        <Container role="main">
          <div className="starter-template mt-2">
            <Accordion defaultActiveKey="0" >
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">Kpi</Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="ml-4 mr-4 mb-1"><Kpi /></Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="1">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">Selezione Sms</Accordion.Toggle>
                <Accordion.Collapse eventKey="1" className="ml-4 mr-4 mb-1"><FilteredCount /></Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="2">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">Export Contatti</Accordion.Toggle>
                <Accordion.Collapse eventKey="2" className="ml-4 mr-4 mb-1">
                  <ExportDump />
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="3">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">Export Reminder</Accordion.Toggle>
                <Accordion.Collapse eventKey="3" className="ml-4 mr-4 mb-1">
                  <ExportReminder />
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="4">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">Export Installatori</Accordion.Toggle>
                <Accordion.Collapse eventKey="4" className="ml-4 mr-4 mb-1">
                  <ExportInstaller/>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </Container>
      </>
    );
  }
}

export { HomePageComp as HomePage };
