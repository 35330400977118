import moment from "moment";
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

export const getDateStringForFileName = () => {
    const date = new Date();
    let formattedDate = (moment(date)).format('YYYY-MM-DD_HH_mm_ss');
    return formattedDate;
}

export const downloadDump = async (filters: any) => {
    console.log(filters);
    const filtersDate:any = {};

    if(moment(filters.createdFrom).isValid()) {
        filtersDate["createdFrom"] = moment(filters.createdFrom).startOf("day").toDate();
    }

    if(moment(filters.createdTo).isValid()) {
        filtersDate["createdTo"] = moment(filters.createdTo).endOf("day").toDate();
    }

    if(moment(filters.sentFrom).isValid()) {
        filtersDate["sentFrom"] = moment(filters.sentFrom).startOf("day").toDate();
    }

    if(moment(filters.sentTo).isValid()) {
        filtersDate["sentTo"] = moment(filters.sentTo).endOf("day").toDate();
    }

    if(moment(filters.feedbackFrom).isValid()) {
        filtersDate["feedbackFrom"] = moment(filters.feedbackFrom).startOf("day").toDate();
    }

    if(moment(filters.feedbackTo).isValid()) {
        filtersDate["feedbackTo"] = moment(filters.feedbackTo).endOf("day").toDate();
    }

    const config = {
        apiUrl: process.env.REACT_APP_API_URL
    }

    const autenticationTokenStorageService = new AutenticationTokenStorageService();

    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(filtersDate)
    };
    console.log(filtersDate);
    return fetch(`${config.apiUrl}/api/smss/exportCsv`, requestOptions).then(response => {
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error(`Unauthorized`);
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }
        return response.blob();
    });

} 

export const downloadInstaller = async ()=>{
    const config = {
        apiUrl: process.env.REACT_APP_API_URL
    }

    const autenticationTokenStorageService = new AutenticationTokenStorageService();

    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: requestHeaders
    };
    return fetch(`${config.apiUrl}/api/associatedinstallers/exportCsv`, requestOptions)
    .then(response => {
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error(`Unauthorized`);
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }
        return response.blob()
    })
}

export const downloadReminder = async (filters: any)=>{
    console.log(filters);
    const filtersDate:any = {};

    if(moment(filters.sentFrom).isValid()) {
        filtersDate["sentFrom"] = moment(filters.sentFrom).startOf("day").toDate();
    }

    if(moment(filters.sentTo).isValid()) {
        filtersDate["sentTo"] = moment(filters.sentTo).endOf("day").toDate();
    }

    if(moment(filters.feedbackFrom).isValid()) {
        filtersDate["feedbackFrom"] = moment(filters.feedbackFrom).startOf("day").toDate();
    }

    if(moment(filters.feedbackTo).isValid()) {
        filtersDate["feedbackTo"] = moment(filters.feedbackTo).endOf("day").toDate();
    }

    const config = {
        apiUrl: process.env.REACT_APP_API_URL
    }

    const autenticationTokenStorageService = new AutenticationTokenStorageService();

    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(filtersDate)
    };
    return fetch(`${config.apiUrl}/api/reminderHistory/exportCsv`, requestOptions)
    .then(response => {
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error(`Unauthorized`);
            } else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }
        return response.blob()
    })
}
