
import React from "react";
import { Alert, Badge, Button, Col, Form } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";

import { default as Select } from 'react-select';

import { list } from "../_actions/filtered.count.actions";
import { activateExport, activateExportReset } from "../_actions/filtered.export.actions";
import { BatchFlow, DeviceTypeModel, FilteredCountModel, FilteredCountState, SmsQueryModel, SmsStateModel } from "../_constants/filtered.count-types";
import { ActivateExportModel, ActivateExportState } from "../_constants/filtered.export-types";
import { AnomalyEventService } from "../_services/anomaly-event-service";

type Props = PropsFromRedux & {
  item: FilteredCountModel,
  filteredCountActions: {
    list: any
  }
  error: Error
} & {
  exportItem?: ActivateExportModel,
  exportError?: Error
}

type State = FilteredCountState & ActivateExportState;

const mapStateToProps = (state: State) => {
  console.log("mapStateToProps.state", state);
  return {
    item: state.filteredCounts?.item,
    error: state.filteredCounts?.error,
    exportError: state.exportStates?.error,
    exportSuccess: state.exportStates?.success,
    loading: state.exportStates?.loading
  };
};

const connector = connect(mapStateToProps, { list, activateExport, activateExportReset});

type PropsFromRedux = ConnectedProps<typeof connector>

interface FilteredCountComponentInternaleState extends Record<string, any> {
  quantity: number,
  submitted: boolean,
}

class FilteredCountComponent extends React.Component<Props, FilteredCountComponentInternaleState> {

  anomalyEventOptions: any[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      quantity: 50,
      submitted: false,
      anomalyEventSelected: [],
      associatedInstaller: undefined,
      notAssociatedInstaller: undefined,
      deviceTypeCode: undefined,
      batchFlow: BatchFlow.AUTOMOTIVE
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.handleRealoadClick = this.handleRealoadClick.bind(this);
    this.handleSelectAnomalyEventChange = this.handleSelectAnomalyEventChange.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.handleResetMsg = this.handleResetMsg.bind(this);
    this.handleChangeFlow = this.handleChangeFlow.bind(this);
  }

  handleRealoadClick(e: any) {
    this.reloadFilteredCounts();
  }

  handleExportClick(e: any) {
    console.log("handleExportClick called ");
    const query = this.getQueryForFilter();
    this.props.activateExport(query);
  }

  reloadFilteredCounts() {
    console.log("reloadFilteredCounts called ");
    const query = this.getQueryForFilter();
    this.props.list(query);
  }

  getQueryForFilter() {
    this.setState({ submitted: true });
    const { quantity, anomalyEventSelected, associatedInstaller, notAssociatedInstaller, deviceType, batchFlow } = this.state;
    let anomalyEventSelectedValues = anomalyEventSelected ? anomalyEventSelected?.map((item: any) => item['value']) : [];
    const intallerAssociated = associatedInstaller === notAssociatedInstaller ? undefined : associatedInstaller;

    let deviceTypeToSend = deviceType;
    if (!Object.keys(DeviceTypeModel).includes(deviceType)) {
      deviceTypeToSend = undefined;
    }

    const query: SmsQueryModel =
    {
      batchFlow: batchFlow,
      limit: quantity,
      eventAnomalies: anomalyEventSelectedValues,
      intallerAssociated: intallerAssociated,
      deviceTypeCode: deviceTypeToSend,
      createdFrom: undefined,
      state: SmsStateModel.TO_PROCESS,
      closed: false
    };

    console.log("query ", query);

    return query;
  }

  handleChange(e: any) {
    const { name, value } = e.target;
    console.log("handleChange " + name + ":" + value);
    this.setState({ [name]: value });
  }

  handleChangeCheckBox(e: any) {
    const { name, value } = e.target;
    console.log("handleChangeCheckBox ", value);
    const newValue = !this.state[name]; //Toggle checked state 
    this.setState({ [name]: newValue });
  }

  handleChangeFlow(e: any) {
    const { value } = e.target;

    this.anomalyEventOptions = [];
    AnomalyEventService.getAll(value).then(
      anomalyEvents => anomalyEvents.map(item => {
        const option = { value: item.id, label: item.name };
        this.anomalyEventOptions.push(option);
        return item;
      })
    );

    this.setState({ batchFlow: value });
    if (value !== BatchFlow.AUTOMOTIVE) {
      this.setState({
        anomalyEventSelected: [],
        associatedInstaller: undefined,
        notAssociatedInstaller: undefined,
        deviceTypeCode: undefined,
        deviceType: undefined
      });
    }

    setTimeout(()=>this.reloadFilteredCounts(), 0);//hack to take new state values
  }

  componentDidMount() {
    AnomalyEventService.getAll(this.state.batchFlow).then(
      anomalyEvents => anomalyEvents.map(item => {
        const option = { value: item.id, label: item.name };
        this.anomalyEventOptions.push(option);
        return item;
      })
    );
    this.reloadFilteredCounts();
  }

  handleSelectAnomalyEventChange = (selectedOption: any) => {
    this.setState({ anomalyEventSelected: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  handleResetMsg = (e: any) => {
    console.log("handleResetMsg called ");
    this.props.activateExportReset();
  };

  render() {
    const { quantity, anomalyEventSelected,
      associatedInstaller, notAssociatedInstaller, deviceType, batchFlow
    } = this.state;
    const { item, error, exportError, exportSuccess } = this.props;
    const alertExportMessageFail = "Invio non avvenuto " + exportError;
    const alertExportMessageSuccess= "Invio avvenuto correttamente";
    return (
      <div className="mt-3">
        <h2>Selezione Sms</h2>
        <h4>Filtri per la selezione degli Sms ed invio</h4>
        {exportError ? <Alert variant="danger" onClose={this.handleResetMsg} dismissible> {alertExportMessageFail} </Alert> : <></> }
        {exportSuccess ? <Alert variant="success" onClose={this.handleResetMsg} dismissible> {alertExportMessageSuccess} </Alert> : <></> }
        {error
          ? <Alert variant="danger" > {error} </Alert>
          :
          <Form>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Flusso</Form.Label>
                  <Form.Check
                    type="radio"
                    name="batchFlow"
                    label="Automotive"
                    value={BatchFlow.AUTOMOTIVE}
                    onChange={this.handleChangeFlow}
                    checked={batchFlow === BatchFlow.AUTOMOTIVE}
                  />
                  <Form.Check
                    type="radio"
                    name="batchFlow"
                    label="Home"
                    value={BatchFlow.HOME}
                    onChange={this.handleChangeFlow}
                    checked={batchFlow === BatchFlow.HOME}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Anomalia</Form.Label>
                  <Select
                    isMulti
                    value={anomalyEventSelected}
                    onChange={this.handleSelectAnomalyEventChange}
                    options={this.anomalyEventOptions}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Tipologia del device</Form.Label>
                  <Form.Check
                    type="radio"
                    name="deviceType"
                    label="Autoinstallante "
                    value="A"
                    onChange={this.handleChange}
                    checked={deviceType === DeviceTypeModel[DeviceTypeModel.A]}
                    disabled={batchFlow !== BatchFlow.AUTOMOTIVE}
                  />

                  <Form.Check
                    type="radio"
                    name="deviceType"
                    label="Professional"
                    value="P"
                    onChange={this.handleChange}
                    checked={deviceType === DeviceTypeModel[DeviceTypeModel.P]}
                    disabled={batchFlow !== BatchFlow.AUTOMOTIVE}
                  />

                  <Form.Check
                    type="radio"
                    name="deviceType"
                    label="Tutti "
                    onChange={this.handleChange}
                    checked={!Object.keys(DeviceTypeModel).includes(deviceType)}
                    disabled={batchFlow !== BatchFlow.AUTOMOTIVE}
                  />
                </Form.Group>

              </Col>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Tiplogia Istallatore</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="associatedInstaller"
                    label="Associato"
                    value="true"
                    onChange={this.handleChangeCheckBox}
                    checked={associatedInstaller}
                    disabled={batchFlow !== BatchFlow.AUTOMOTIVE}
                  />
                  <Form.Check
                    type="checkbox"
                    name="notAssociatedInstaller"
                    value="true"
                    label="Non associato"
                    onChange={this.handleChangeCheckBox}
                    checked={notAssociatedInstaller}
                    disabled={batchFlow !== BatchFlow.AUTOMOTIVE}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group >
                  <Form.Label>Numero di contatti</Form.Label>
                  <Form.Control type="number" name="quantity" placeholder="Quantità" onChange={this.handleChange} value={quantity} />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col></Col>
              <Col></Col>
              <Col style={{textAlign: "right"}}>
                <Form.Group>
                  <Button variant="primary" type="button" onClick={this.handleRealoadClick} style={{width:"120px"}}>Aggiorna</Button>
                </Form.Group>
                <Form.Group>
                  <Button variant="primary" type="button" onClick={this.handleExportClick} style={{width:"120px"}}>Invia</Button>
                </Form.Group>
              </Col>
            </Form.Row>
            <hr className="mt-4" />
            <Form.Row>
              <Col>
                <h4> Numero di contatti selezionati <Badge variant="dark"> {item?.countFiltered}  </Badge> </h4>
              </Col>
              <Col></Col>
            </Form.Row>
          </Form>
        }
      </div>
    );
  }
}

const connList = connector(FilteredCountComponent);

export { connList as FilteredCount };
