import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { App } from './App'
import configureStore from './_store'

// setup fake backend
import { configureFakeBackend } from './_helpers';

if (process.env.REACT_APP_USE_FAKE_AUTH_BACKEND && process.env.REACT_APP_USE_FAKE_AUTH_BACKEND === 'true') {
  console.log(`Configure Fake bakend`);
  configureFakeBackend();
} 

const store = configureStore({});

const renderApp = () =>
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp)
}

renderApp();
