import {
  ActivateExportActionTypes,
  ACTIVATE_EXPORT_REQUEST,
  ACTIVATE_EXPORT_SUCCESS,
  ACTIVATE_EXPORT_FAILURE,
  ACTIVATE_EXPORT_RESET_ERR_AND_MSG,
} from '../_constants/filtered.export-types';

export type ActivateExportState = { loading: boolean } | { error: Error } | any;

const initialState: ActivateExportState = {
    loading: false
}

export function exportStates(state = initialState, action: ActivateExportActionTypes): ActivateExportState {
  switch (action.type) {
    case ACTIVATE_EXPORT_REQUEST:
      return { loading: true };
    case ACTIVATE_EXPORT_SUCCESS:
      return { loading: false, success: true };
    case ACTIVATE_EXPORT_FAILURE:
        return Object.assign({}, {
          loading: false,
          error: action.payload
        });
    case ACTIVATE_EXPORT_RESET_ERR_AND_MSG:
      return { loading: false };

    default:
      return state
  }
}

