import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { KpiState, SmsQueryModelForKpi } from "../_constants/kpi-types";
import { listKpi } from "../_actions/kpi.actions";
import { KpiModel } from "../_constants/kpi-types";
import { Alert, Badge, Button, Col, Form } from "react-bootstrap";
import moment from "moment";


const kpiAutoUpdateActive: boolean = true;

type Props = PropsFromRedux & {
  item: KpiModel,
  kpiActions: {
    list: any
  }
  error: Error,
  setTimeout: any
}

const mapStateToProps = (state: KpiState) => {
  console.log("state", state);
  return {
    item: state.kpis.item,
    error: state.kpis.error
  };
};

const connector = connect(mapStateToProps, { list: listKpi });

type PropsFromRedux = ConnectedProps<typeof connector>

interface FilteredCountComponentInternaleState extends Record<string, any> {
  ageInHours: number,
  submitted: boolean,
}

let timeOutVar:any;

class KpiConnectedComponent extends React.Component<Props, FilteredCountComponentInternaleState> {

  constructor(props: Props) {
    super(props);

    this.state = {
      ageInHours: 3,
      submitted: false,
    };

    this.handleRealoadClick = this.handleRealoadClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
  }

  reloadFilteredCounts() {
    this.setState({ submitted: true });
    const { ageInHours } = this.state;
    const query: SmsQueryModelForKpi =
    {
      olderThan: moment().subtract(ageInHours, "hours").toDate()
    };

    console.log("query ", query);
    this.props.list(query);
  }

  handleRealoadClick(e: any) {
    this.reloadFilteredCounts();
  }

  componentDidMount() {
    console.log("componentDidMount called ...")
    clearTimeout(timeOutVar);

    if (kpiAutoUpdateActive) {
      timeOutVar = setInterval(
        () => {
        console.log("timeout expired ...");
        this.reloadFilteredCounts(); 
      }, 30000); //mills
    }
    
  }

  componentWillUnmount() {
    clearTimeout(timeOutVar);
   };

  handleChange(e: any) {
    const { name, value } = e.target;
    console.log("handleChange " + name + ":" + value);
    this.setState({ [name]: value });
  }

  render() {
    const { item, error } = this.props;
    const { ageInHours } = this.state;
    return (
      <div className="mt-3">
        <h2>Kpi</h2>
        {error ? null :
          <div className="mt-4">
            <Form>
              <Form.Row>
                <Col>
                  <ul>
                    <li>Errori di sistema rilevati: <Badge variant="danger"> {item?.unresetRecodedError}  </Badge></li>
                    <li>di cui <strong>scarti file contatti <i>automotive</i></strong>: <Badge variant="danger"> {item?.rejectedContact}  </Badge></li>
                    <li>di cui <strong>scarti file installatori</strong>: <Badge variant="danger"> {item?.rejectedInstaller}  </Badge></li>
                    <li>di cui <strong>scarti file contatti <i>home</i></strong>: <Badge variant="danger"> {item?.rejectedContactHome}  </Badge></li>
                  </ul>
                </Col>
              </Form.Row>
            </Form>
          </div>
        }
        <h4>Conteggi e statistiche degli Sms nella <span className="font-italic" >Staging Area</span></h4>
        {error
          ? <Alert variant="danger"> {error} </Alert>
          :
          <div className="mt-4">
             <Form>
             <Form.Row>
               <Col>
                <ul>
                <li>Da lavorare: <Badge variant="success"> {item?.countProcessable}  </Badge></li>
                <li>Invio ritardato: <Badge variant="warning"> {item?.countSendDelay}  </Badge></li>
                <li>Da lavorare in area da oltre <strong>{ageInHours}</strong> ore: <Badge variant="danger"> {item?.countProcessableCreatedFrom}  </Badge></li>
                <li>Non lavorabili: <Badge variant="success"> {item?.countUnProcessable}  </Badge></li>
                <li>Invio richiesto: <Badge variant="success"> {item?.countSendingRequired}  </Badge></li>
                <li>Inviati: <Badge variant="success"> {item?.countSent}  </Badge></li>
                <li>Feedback ricevuto: <Badge variant="success"> {item?.countFeedbackReceived}  </Badge> </li>
                <li>Errore d'invio: <Badge variant="danger"> {item?.countSendingError}  </Badge></li>
                </ul>
                </Col>
                <Col>
                    <Form.Group>
                    <Form.Label>Filtro ore in Staging</Form.Label>
                    <Form.Control type="number" name="ageInHours"
                      placeholder="Ore"                 
                      onChange={this.handleChange} value={ageInHours} style={{width:"120px"}}/>
                  </Form.Group>
                  <Form.Group>
                  <Button variant="primary" type="button" onClick={this.handleRealoadClick} style={{width:"120px"}}>Aggiorna </Button>
                  </Form.Group>
                </Col>
              </Form.Row>                        
            </Form>

          </div>
        }
        <div className="mt-3">
        <h4>Conteggi e Statistiche dei Reminder</h4>
          {error ? null :
            <div className="mt-3">
              <Form>
                <Form.Row>
                  <Col>
                    <ul>
                      <li>Inviati: <Badge variant="success"> {item?.countReminderSent}  </Badge></li>
                      <li>Feedback Ricevuto: <Badge variant="success"> {item?.countReminderFeedbackReceived}  </Badge></li>
                      <li>Errore d'invio: <Badge variant="danger"> {item?.countReminderSendingError}  </Badge></li>
                    </ul>
                  </Col>
                </Form.Row>
              </Form>
            </div>
          }
        </div>
      </div>
    );
  }
}

const connList = connector(KpiConnectedComponent);

export { connList as Kpi };
