import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { downloadReminder, getDateStringForFileName } from "../_actions/export-dump-actions";
import { saveAs } from 'file-saver';
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";
import moment from "moment";

class ExportReminder extends React.Component {

  autenticationTokenStorageService = new AutenticationTokenStorageService();
  constructor(props: any) {
    super(props);

    this.dateChange = this.dateChange.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
  }

  state = {
    downloading: false,
    sentFrom: "",
    sentTo: "",
    feedbackFrom: "",
    feedbackTo: ""
  };

  dateChange(e: any) {
    const { name, value } = e.target;
    console.log("dateChange " + name + ":" + value);
    this.setState({ [name]: value });
  }

  resetDate = (e: any) => {

    this.setState({
      sentFrom: "",
      sentTo: "",
      feedbackFrom: "",
      feedbackTo: ""
    })
  }

  handleDownloadClick = (e: any) => {
    this.setState({ downloading: true });
    downloadReminder(this.state).then(blob => saveAs(blob, getDateStringForFileName() + '_export_Reminder_SmsStagingArea.csv'))
      .then(() => this.setState({ downloading: false }))
      .catch((error: Error) => {
        this.autenticationTokenStorageService.logoutIfNotAuthorized(error);
      });
  }

  render() {
    const state = this.state;
    const now = moment().format("YYYY-MM-DD");
    return (
      <div className="mt-3">
        <h2>Download Reminder</h2>

        <div className="input-group">
          <div className="input-group-prepend">
            <span style={{ width: "220px" }} className="input-group-text" id="">Reminder Inviato Dal - Al</span>
          </div>
          <input max={state.sentTo || now} name="sentFrom" onChange={this.dateChange} value={state.sentFrom} type="date" className="form-control" />
          <input max={now} min={state.sentFrom} name="sentTo" onChange={this.dateChange} value={state.sentTo} type="date" className="form-control" />
        </div>
        <br />
        <div className="input-group">
          <div className="input-group-prepend">
            <span style={{ width: "220px" }} className="input-group-text" id="">Feedback Ricevuto Dal - Al</span>
          </div>
          <input max={state.feedbackFrom || now} name="feedbackFrom" onChange={this.dateChange} value={state.feedbackFrom} type="date" className="form-control" />
          <input max={now} min={state.feedbackTo} name="feedbackTo" onChange={this.dateChange} value={state.feedbackTo} type="date" className="form-control" />
        </div>

        <div className="mt-4">
          {
            state.downloading ?
              <div className="mt-4">
                <Button style={{ width: "120px" }} type='button'><Spinner animation="border" variant="light" size="sm" /></Button>
                <Button className="btn-secondary" style={{ marginLeft: "5px", width: "120px" }} type='button' onClick={this.resetDate} disabled={state.downloading}>Reset Filtri</Button>
              </div>
              :
              <div className="mt-4">
                <Button style={{ width: "120px" }} type='button' onClick={this.handleDownloadClick}>Download</Button>
                <Button className="btn-secondary" style={{ marginLeft: "5px", width: "120px" }} type='button' onClick={this.resetDate} disabled={state.downloading}>Reset Filtri</Button>
              </div>
          }
        </div>
      </div>
    );
  }
}

export { ExportReminder };