import React from 'react';
import { logout } from "../_actions/auth-actions";

import { Navbar,Nav } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';
import { User } from '../_constants/auth-types';
import { AutenticationTokenStorageService } from '../_services/autentication.token.storage.service';

import logo from '../logo.png';

type Props = PropsFromRedux & { user: User }

export interface BarNavState { }

const mapStateToProps = (state: BarNavState) => { 
  return {  };
};

const connector = connect(mapStateToProps, { logout });

type PropsFromRedux = ConnectedProps<typeof connector>

class ConnectedBarNav extends React.Component<Props, BarNavState> {
  constructor(props: Props) {
    super(props);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  handleLogoutClick(e: any) {
    this.props.logout();
  }

  render() {    
    const userFromStore:User = (new AutenticationTokenStorageService()).getLoggeUser();
    return (
      <>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">
        <img src={logo} width="150" height="40" className="d-inline-block align-top" alt=""/>         
        </Navbar.Brand>
        <Nav className="mr-auto" >
        <Nav.Link>Sms Staging Area</Nav.Link>
        </Nav> 
        <Nav>
          <Nav.Link onClick={this.handleLogoutClick} >Logout [ {userFromStore.username} ]</Nav.Link>
        </Nav> 
      </Navbar>  
    </>
    );
  }
}

const connBarNav = connector(ConnectedBarNav);

export { connBarNav as BarNav };