import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Dispatch } from 'redux';

import { history } from './_helpers';

import { PrivateRoute } from './_components';
import { HomePage } from './HomePage';
import { LoginPage } from './LoginPage';
import { ChangePasswordPage } from './ChangePasswordPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import { User } from './_constants/auth-types';


interface RootState {
    auth: {
        logged: boolean,
        user: User
    }    
}
interface Prop {
    logged:boolean,
    user: User
}

class App extends React.Component<Prop, RootState> {

    render() {        
        //const { logged, user } = this.props;
        //const userFromStore:User = (new AutenticationTokenStorageService()).getLoggeUser();
        return (            
            <>
                { /* <span>logged: {user ? user.username : null } - {logged}</span>
                <span>logged store: {userFromStore ? userFromStore.username : null } </span>  */}
                <Router history={history}>
                        { /* <PrivateRoute exact path="/" component={HomePage} />  */}
                        <PrivateRoute exact path="/" component={HomePage} />
                        <Route path="/changepassword" component={ChangePasswordPage} />
                        <Route path="/login" component={LoginPage} />
                </Router>
            </>
        );
    }
}

function mapStateToProps(state: RootState) {
    const { user } = state.auth;
    const logged = user ? true : false;
    return {
        user,
        logged
    };
}

function mapDispatchToProps(dispatchIn: Dispatch) {
    return {
        dispatch: dispatchIn
    };
  }

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App }; 


