
import { Dispatch } from "react";
import { SmsQueryModel } from "../_constants/filtered.count-types";
import { ActivateExportActionTypes, ACTIVATE_EXPORT_FAILURE, ACTIVATE_EXPORT_REQUEST, ACTIVATE_EXPORT_RESET_ERR_AND_MSG, ACTIVATE_EXPORT_SUCCESS } from "../_constants/filtered.export-types";
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

//https://redux.js.org/recipes/usage-with-typescript


export const filteredCountActions = {
  activateExport
};

// TypeScript infers that this function is returning FetchFilteredCountRequestAction
function activateExportRequest(): ActivateExportActionTypes {
  return {
    type: ACTIVATE_EXPORT_REQUEST
  }
}

function activateExportSuccess(): ActivateExportActionTypes {
  return {
    type: ACTIVATE_EXPORT_SUCCESS,
  }
}

function activateExportFailure(error: Error): ActivateExportActionTypes {
  return {
    type: ACTIVATE_EXPORT_FAILURE,
    payload: error.message
  }
}

function activateExportResetErrAndMsg(): ActivateExportActionTypes {
  return {
    type: ACTIVATE_EXPORT_RESET_ERR_AND_MSG
  }
}

const config = {
  apiUrl: process.env.REACT_APP_API_URL
}
const autenticationTokenStorageService = new AutenticationTokenStorageService();

export function activateExport(query: SmsQueryModel) {
  console.log("activateExport query", query);
  console.log("JSON.stringify(query)", JSON.stringify(query));

  return function (dispatch: Dispatch<any>) {
    dispatch(activateExportRequest());
    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(query)
      //body: query
    };

    return fetch(`${config.apiUrl}/api/smss/activateExport`, requestOptions)
      .then(response => {
        if (response.status === 570) {
          response.json()
          .then(json => {
            console.log("json", json);
            throw new Error(` 570 , ${json.error}`);
          })
          .catch((error: Error) => {
            dispatch(activateExportFailure(error));
          })
        }
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(`Unauthorized`);
          } else {
            throw new Error(`${response.status} ${response.statusText}`);
          }
        }
        return response;
      }
      )
      .then(response => dispatch(activateExportSuccess()))
      .catch((error: Error) => {
        dispatch(activateExportFailure(error));
        autenticationTokenStorageService.logoutIfNotAuthorized(error);
      })
  };
}

export function activateExportReset() {
  return function (dispatch: Dispatch<any>) {
    console.log("activateExportReset called");
    dispatch(activateExportResetErrAndMsg());
  }  
}
