
export interface User {
    id: number;
    token: string;
    username: string;
    password: string;
    groupId: string;
}

export interface LoginState {
    auth: {
        user: User,
        loading: boolean
        logged: boolean
        error: Error
    }
}

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export interface LoginRequestAction {
    type: typeof LOGIN_REQUEST
    //payload: Message
}
export interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS,
    payload: User
}
export interface LoginFailureAction {
    type: typeof LOGIN_FAILURE,
    payload: string
}

export type LoginActionTypes = LoginRequestAction | LoginSuccessAction | LoginFailureAction