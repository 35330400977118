import {
  User,
  LoginActionTypes,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from '../_constants/auth-types'

export type LoginState = { loading: boolean } | { items: User[] } | { error: Error } | any;

const initialState: LoginState = {
  loading: false,
  logged: false
}

export function auth(state = initialState, action: LoginActionTypes): LoginState {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        user: [],
        logged: false
      }
    case LOGIN_SUCCESS:
      return Object.assign({}, {
        loading: false,
        logged: true,
        user: action.payload
      })
    case LOGIN_FAILURE:
        return Object.assign({}, {
          loading: false,
          logged: false,
          error: action.payload
        });

    default:
      return state
  }
}

