import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { downloadDump, getDateStringForFileName } from "../_actions/export-dump-actions";
import { saveAs } from 'file-saver';
import moment from "moment";
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

class ExportDump extends React.Component {

    autenticationTokenStorageService = new AutenticationTokenStorageService();

    state = {
        createdFrom: "",
        createdTo: "",
        sentFrom: "",
        sentTo: "",
        feedbackFrom: "",
        feedbackTo: "",
        downloading: false
    };

    constructor(props: any) {
        super(props);

        this.dateChange = this.dateChange.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
    }

    dateChange(e: any) {
        const { name, value } = e.target;
        console.log("dateChange " + name + ":" + value);
        this.setState({ [name]: value });
    }

    resetDate = (e: any) => {
        
        this.setState({
            createdFrom: "",
            createdTo: "",
            sentFrom: "",
            sentTo: "",
            feedbackFrom: "",
            feedbackTo: ""
        })
    }

    handleDownloadClick = (e: any) => {
        this.setState({downloading: true});
        downloadDump(this.state).then(blob => saveAs(blob, getDateStringForFileName() + '_export_Dump_SmsStagingArea.csv'))
        .then(()=>this.setState({downloading: false}))
        .catch((error:Error)=>{
            this.autenticationTokenStorageService.logoutIfNotAuthorized(error);
          });
    }

    render() {
        const state = this.state;
        const now = moment().format("YYYY-MM-DD");
        console.log(now)
        return (
            <div className="mt-3">
                <h2>Download Contact/Sms Data</h2>
                <br />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span style={{ width: "220px" }} className="input-group-text" id="">Contatto Caricato Dal - Al</span>
                    </div>
                    <input max={state.createdTo || now} name="createdFrom" onChange={this.dateChange} value={state.createdFrom} type="date" className="form-control" />
                    <input max={now} min={state.createdFrom} name="createdTo" onChange={this.dateChange} value={state.createdTo} type="date" className="form-control" />
                </div>
                <br />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span style={{ width: "220px" }} className="input-group-text" id="">Contatto Inviato Dal - Al</span>
                    </div>
                    <input max={state.sentTo || now} name="sentFrom" onChange={this.dateChange} value={state.sentFrom} type="date" className="form-control" />
                    <input max={now} min={state.sentFrom} name="sentTo" onChange={this.dateChange} value={state.sentTo} type="date" className="form-control" />
                </div>
                <br />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span style={{ width: "220px" }} className="input-group-text" id="">Feedback Ricevuto Dal - Al</span>
                    </div>
                    <input max={state.feedbackTo || now} name="feedbackFrom" onChange={this.dateChange} value={state.feedbackFrom} type="date"  className="form-control" />
                    <input max={now} min={state.feedbackFrom} name="feedbackTo" onChange={this.dateChange} value={state.feedbackTo} type="date" className="form-control" />
                </div>
                {
                    state.downloading ?
                        <div className="mt-4">
                            <Button style={{ marginRight: "5px", width: "120px" }} type='button'><Spinner animation="border" variant="light" size="sm" /></Button>
                            <Button className="btn-secondary" style={{ marginLeft: "5px", width: "120px" }} type='button'><Spinner animation="border" variant="light" size="sm" /></Button>
                        </div>
                        :
                        <div className="mt-4">
                            <Button style={{ marginRight: "5px", width: "120px" }} type='button' onClick={this.handleDownloadClick}>Download</Button>
                            <Button className="btn-secondary" style={{ marginLeft: "5px", width: "120px" }} type='button' onClick={this.resetDate} disabled={state.downloading}>Reset Filtri</Button>
                        </div>
                }
            </div>
        );
    }
}

export { ExportDump };