import {
  KpiModel,
  KpiActionTypes,
  FETCH_KPI_REQUEST,
  FETCH_KPI_SUCCESS,
  FETCH_KPI_FAILURE
} from '../_constants/kpi-types'

export type KpiState = { loading: boolean } | { items: KpiModel[] } | { error: Error } | any;

const initialState: KpiState = {
  items: [],
  loading: false
}

export function kpis(state = initialState, action: KpiActionTypes): KpiState {
  switch (action.type) {
    case FETCH_KPI_REQUEST:
      return {
        loading: true,
        item: []
      }
    case FETCH_KPI_SUCCESS:
      return Object.assign({}, {
        loading: false,
        //item: state.item.concat(action.payload)
        item: action.payload
      })
    case FETCH_KPI_FAILURE:
        return Object.assign({}, {
          loading: false,
          error: action.payload
        });

    default:
      return state
  }
}

