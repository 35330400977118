export interface ActivateExportModel {

}

export interface ActivateExportState {
    exportStates: {
        loading: boolean
        error?: Error
        success?: boolean
    }
}

export const ACTIVATE_EXPORT_REQUEST = 'ACTIVATE_EXPORT_REQUEST';
export const ACTIVATE_EXPORT_SUCCESS = 'ACTIVATE_EXPORT_SUCCESS';
export const ACTIVATE_EXPORT_FAILURE = 'ACTIVATE_EXPORT_FAILURE';
export const ACTIVATE_EXPORT_RESET_ERR_AND_MSG = 'ACTIVATE_EXPORT_RESET_ERR_AND_MSG';

export interface ActivateExportRequestAction {
    type: typeof ACTIVATE_EXPORT_REQUEST
}
export interface ActivateExportSuccessAction {
    type: typeof ACTIVATE_EXPORT_SUCCESS,
}
export interface ActivateExportActionFailure {
    type: typeof ACTIVATE_EXPORT_FAILURE,
    payload: string
}
export interface ActivateExportResetErrAndMsg {
    type: typeof ACTIVATE_EXPORT_RESET_ERR_AND_MSG
}

export type ActivateExportActionTypes = ActivateExportRequestAction | ActivateExportSuccessAction | ActivateExportActionFailure | ActivateExportResetErrAndMsg
