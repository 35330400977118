export interface FilteredCountModel {
    countFiltered: number;
}

export interface FilteredCountState {
    filteredCounts: {
        item: FilteredCountModel,
        loading: boolean
        error: Error
    }
}

export enum SmsStateModel {
    TO_PROCESS = "TO_PROCESS",
}

export enum BatchFlow {
    AUTOMOTIVE = "AUTOMOTIVE",
    HOME = "HOME"
}

export enum DeviceTypeModel { A, P } //order matters !!

export interface SmsQueryModel  {
    deviceTypeCode: DeviceTypeModel | undefined;
    intallerAssociated: boolean | undefined;
    eventAnomalies: string[] | undefined;
    limit:number | undefined;
    createdFrom: Date | undefined;
    state: SmsStateModel | undefined;
    closed: boolean | undefined;
    batchFlow: BatchFlow;
 }

export const FETCH_FILTERED_COUNT_REQUEST = 'FETCH_FILTERED_COUNT_REQUEST';
export const FETCH_FILTERED_COUNT_SUCCESS = 'FETCH_FILTERED_COUNT_SUCCESS';
export const FETCH_FILTERED_COUNT_FAILURE = 'FETCH_FILTERED_COUNT_FAILURE';

export interface FetchFilteredCountRequestAction {
    type: typeof FETCH_FILTERED_COUNT_REQUEST
    //payload: FilteredCountModel
}
export interface FetchFilteredCountSuccessAction {
    type: typeof FETCH_FILTERED_COUNT_SUCCESS,
    payload: FilteredCountModel
}
export interface FetchFilteredCountFailureAction {
    type: typeof FETCH_FILTERED_COUNT_FAILURE,
    payload: string
}

export type FilteredCountActionTypes = FetchFilteredCountRequestAction | FetchFilteredCountSuccessAction | FetchFilteredCountFailureAction
