import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { downloadInstaller, getDateStringForFileName } from "../_actions/export-dump-actions";
import { saveAs } from 'file-saver';
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

class ExportInstaller extends React.Component {

  autenticationTokenStorageService = new AutenticationTokenStorageService();
        constructor(props: any) {
        super(props);

        this.handleDownloadClick = this.handleDownloadClick.bind(this);
    }

    state = {
      downloading: false
    };

    handleDownloadClick = (e: any) => {
        this.setState({downloading: true});
        downloadInstaller().then(blob => saveAs(blob, getDateStringForFileName() + '_export_Installer_SmsStagingArea.csv'))
        .then(()=>this.setState({downloading: false}))
        .catch((error:Error)=>{
          this.autenticationTokenStorageService.logoutIfNotAuthorized(error);
        });
    }

    render() {
        const state = this.state;
        return (
            <div className="mt-3">
            <h2>Download Installatori</h2>
            <div className="mt-4">
              {
                state.downloading ?
                  <Button style={{ width: "120px" }} type='button'><Spinner animation="border" variant="light" size="sm" /></Button>
                  :
                  <Button style={{ width: "120px" }} type='button' onClick={this.handleDownloadClick}>Download</Button>
              }
            </div>
          </div>
        );
    }
}

export { ExportInstaller };