import { User } from '../_constants/auth-types';

const LOGGED_USER_KEY = 'sms-staging-area-Loggeduser';

export class AutenticationTokenStorageService {

  private storage: Storage;

  constructor() {
    this.storage = window.sessionStorage;
  }

  public clean() {
    this.storage.removeItem(LOGGED_USER_KEY);
    this.storage.clear();
  }

  public save(user: User) {
    user.password = ""; //Blanking it for security reason
    this.storage.removeItem(LOGGED_USER_KEY);
    this.storage.setItem(LOGGED_USER_KEY, JSON.stringify(user));
  }

  public getLoggeUser(): User {
    const logged = this.storage.getItem(LOGGED_USER_KEY);
    return logged ? JSON.parse(logged) : null;
  }

  public getToken(): string | null {
    return this.getLoggeUser() != null ? this.getLoggeUser().token : null;
  }

  public addAuthHeader(requestHeaders: Headers) {
    // return authorization header with jwt token
    // let user = JSON.parse(localStorage.getItem('user'));
    const user = this.getLoggeUser();

    if (user && user.token) {
      requestHeaders.append("Authorization", user.token);
    }
  
  /*  requestHeaders.forEach((value: string, key: string) => {
      console.log(key + ":" + value);
    }); */
    
  }

  /**
   * Alias for clean();
   */
  public logout() {
    // remove user from local storage to log user out
    this.clean();
  }

   /**
   * Alias for save();
   */
  public login(user: User) {
    // remove user from local storage to log user out
    this.save(user);
  }

  public logoutIfNotAuthorized(error: Error){
    if(error.message === "Unauthorized"){
      this.logout();
      window.location.reload();
    }
  }
  
}

