import { LOGIN_REQUEST, LOGIN_SUCCESS, LoginActionTypes, User, LOGIN_FAILURE } from "../_constants/auth-types";
import { Dispatch } from "react";
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

export const loginActions = {
    login,
};

// TypeScript infers that this function is returning LoginRequestAction
function loginRequest(): LoginActionTypes {
    return {
        type: LOGIN_REQUEST
    }
}

function loginSuccess(user: User): LoginActionTypes {
    return {
        type: LOGIN_SUCCESS,
        payload: user
    }
}

function loginFailure(error: Error): LoginActionTypes {
    return {
        type: LOGIN_FAILURE,
        payload: error.message
    }
}

const config = {
    apiUrl: process.env.REACT_APP_API_URL
}

const autenticationTokenStorageService = new AutenticationTokenStorageService();

export function login(username: string, password: string) {
    return function (dispatch: Dispatch<any>) {
        dispatch(loginRequest());

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({ username: username, password: password }),
        };

        return fetch(`${config.apiUrl}/api/auth/login`, requestOptions)
            .then(response => {
                // if (!response.ok) {
                //     throw new Error(`${response.status} ${response.statusText}`);
                // }
                return response;
            }
            )
            .then(response => response.json())
            .then(json => {
                if(json.error){
                    if(json.error.expired){
                        throw new Error("Password Expired");
                    }else{
                        throw new Error(json.error.message);
                    }
                }
                autenticationTokenStorageService.login(json);
                //history.push("/smss"); //TODO may be better to redirect at url requested before login redirect..                
                dispatch(loginSuccess(json));
                window.location.assign("/");        //TODO ...  
            })
            .catch((error: Error) => {
                dispatch(loginFailure(error));
                //
                // if(error.message === "PasswordExpired"){
                //     window.location.assign("/changepassword");
                // }
            })
    }
}

export function changePassword(username: string, oldpassword: string, newpassword: string) {
    return function (dispatch: Dispatch<any>) {
        dispatch(loginRequest());

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({ username: username, oldPassword: oldpassword, newPassword: newpassword }),
        };

        return fetch(`${config.apiUrl}/api/auth/changePassword`, requestOptions)
            .then(response => {
                // if (!response.ok) {
                //     throw new Error(`${response.status} ${response.statusText}`);
                // }
                return response;
            }
            )
            .then(response => response.json())
            .then(json => {
                if(json.error){
                    throw new Error(json.error.message);
                }
                autenticationTokenStorageService.login(json);
                //history.push("/smss"); //TODO may be better to redirect at url requested before login redirect..                
                dispatch(loginSuccess(json));
                window.location.assign("/");        //TODO ...  
            })
            .catch((error: Error) => {
                dispatch(loginFailure(error));
            })
    }
}

export function logout() {
    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: requestHeaders
    };

    autenticationTokenStorageService.logout();
    window.location.assign("/");
    return fetch(`${config.apiUrl}/api/auth/logout`, requestOptions)
        .then(response => {
            if (!response.ok) {
                console.log("logout success");
            }
            return response;
        });

}

