import React from 'react';
import { Container } from 'react-bootstrap';
import { connect, ConnectedProps } from "react-redux";
import { LoginState, User } from '../_constants/auth-types';
import { changePassword } from "../_actions/auth-actions";

// import './login.css'; // Tell webpack that Button.js uses these styles

type Props = PropsFromRedux & {
    user: User,
    logged: boolean,
    loading: boolean,
    error: Error
}

const mapStateToProps = (state: LoginState) => {
    return {
        user: state.auth.user,
        logged: state.auth.logged,
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const connector = connect(mapStateToProps, { changePassword });

type PropsFromRedux = ConnectedProps<typeof connector>

interface ChangePasswordInternalState extends Record<string,any> {
    username: string,
    password: string,
    newpassword: string,
    confirmnewpassword: string,
    submitted: boolean,
}

class ChangePasswordPage extends React.Component<Props, ChangePasswordInternalState> {
    constructor(props: Props) {
        super(props);

        // reset login status
        //this.props.dispatch(userActions.logout());

        this.state = {
            username: '',
            password: '',
            newpassword: '',
            confirmnewpassword: '',
            submitted: false
        };        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.newPasswordIsValid = this.newPasswordIsValid.bind(this);
    }

    handleChange(e: any) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e: any) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password, newpassword, confirmnewpassword } = this.state;

        if (username && password && this.newPasswordIsValid(newpassword) && confirmnewpassword===newpassword && newpassword!==password) {
            this.props.changePassword(username, password, newpassword);
        }
    }

    newPasswordIsValid(newpassword: string){
        const has2digits = (/.*\d.*\d.*/).test(newpassword);
        const hasUpperCase = (/.*[A-Z].*/).test(newpassword);
        const hasSymbol = (/.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>\\/?€£¥₹§±].*/).test(newpassword);
        return newpassword.length>=8 && has2digits && hasUpperCase && hasSymbol;
    }

    render() {
        const { loading, error } = this.props;
        const { username, password, newpassword, confirmnewpassword, submitted } = this.state;        
        return (            
        <>
                <Container role="main-login" className="main-login-container">
                    <h1 className="text-center">Sms Staging Area</h1>
                    { /*  <span>logged: {user ? user.username : null }</span> */}
                    <form className="form-signin" onSubmit={this.handleSubmit}>
                        <div className="text-center mb-4">
                            <h2>Cambio Password</h2>
                        </div>
                        {error &&
                            <div style={{fontSize: "16px", color: "red", textAlign: "center"}} className="help-block">{error}</div>
                        }
                        { /*- <Alert/>  */}
                        <div className={'form-label-group form-group' + (submitted && !username ? ' has-error' : '')}>
                            <input type="text" className="form-control" name="username" value={username}
                                onChange={this.handleChange} placeholder="Username" required autoFocus />
                            {submitted && !username &&
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">Username obbligatorio</div>
                            }
                        </div>
                        <div className={'form-label-group form-group' + (submitted && !password ? ' has-error' : '')}>
                            <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} required placeholder="Password" />
                            {submitted && !password &&
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">Password obbligatoria</div>
                            }
                        </div>

                        <div className={'form-label-group form-group' + (submitted && !password ? ' has-error' : '')}>
                            <input type="password" className="form-control" name="newpassword" value={newpassword} onChange={this.handleChange} required placeholder="Nuova Password" />
                            {submitted && !newpassword &&
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">Nuova Password obbligatoria</div>
                                
                            }
                            {
                                submitted && newpassword && !this.newPasswordIsValid(newpassword) && 
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">La nuova password non soddisfa i vincoli richiesti</div>
                            }
                            {
                                submitted && newpassword && password === newpassword && 
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">La nuova password deve essere diversa dalla precedente</div>
                            }
                        </div>

                         <div className={'form-label-group form-group' + (submitted && !password ? ' has-error' : '')}>
                            <input type="password" className="form-control" name="confirmnewpassword" value={confirmnewpassword} onChange={this.handleChange} required placeholder="Conferma Nuova Password" />
                            {submitted && !confirmnewpassword &&
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">Conferma la nuova password</div>
                            }
                            {
                                submitted && confirmnewpassword && newpassword !== confirmnewpassword && 
                                <div style={{fontSize: "12px", color: "red", textAlign: "center"}} className="help-block">La password non corrisponde</div>
                            }
                        </div>        
                        <div>
                            <p style={{fontSize: "12px", margin: "0px"}}>La nuova password deve soddisfare i seguenti requisiti:</p>
                            <ul>
                                <li style={{fontSize: "12px"}}>Deve essere lunga almeno 8 caratteri</li>
                                <li style={{fontSize: "12px"}}>Deve contenere almeno 2 cifre</li>
                                <li style={{fontSize: "12px"}}>Deve contenere almeno 1 maiuscola</li>
                                <li style={{fontSize: "12px"}}>Deve contenere almeno 1 simbolo</li>
                                <li style={{fontSize: "12px"}}>Deve differire dalle ultime 3 password usate</li>
                            </ul>
                        </div>
                        <div className="form-label-group form-group">
                            <button className="btn btn-lg btn-primary btn-block">Cambia Password e accedi</button>
                            {loading &&
                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </div>

                    </form>
                </Container>
            </>
        );
    }
}

const connChangePasswordPaget = connector(ChangePasswordPage);
export { connChangePasswordPaget as ChangePasswordPage };


