import { FETCH_KPI_REQUEST, FETCH_KPI_SUCCESS, KpiActionTypes, KpiModel, FETCH_KPI_FAILURE, SmsQueryModelForKpi } from "../_constants/kpi-types";
import { Dispatch } from "react";
import { AutenticationTokenStorageService } from "../_services/autentication.token.storage.service";

//https://redux.js.org/recipes/usage-with-typescript


export const kpiActions = {
  list: listKpi,
};

// TypeScript infers that this function is returning FetchKpiRequestAction
function fetchKpiRequest(): KpiActionTypes {
  return {
    type: FETCH_KPI_REQUEST
  }
}

function fetchKpiSuccess(item: KpiModel): KpiActionTypes {
  return {
    type: FETCH_KPI_SUCCESS,
    payload: item
  }
}

function fetchKpiFaulure(error: Error): KpiActionTypes {
  return {
    type: FETCH_KPI_FAILURE,
    payload: error.message
  }
}

const config = {
  apiUrl: process.env.REACT_APP_API_URL
}
const autenticationTokenStorageService = new AutenticationTokenStorageService();

export function listKpi(query? : SmsQueryModelForKpi) {
  return function (dispatch: Dispatch<any>) {
    dispatch(fetchKpiRequest());
    const requestHeaders: HeadersInit = new Headers();
    autenticationTokenStorageService.addAuthHeader(requestHeaders);
    requestHeaders.append("Content-Type", 'application/json');

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(query)
    };

    return fetch(`${config.apiUrl}/api/kpi`, requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(`Unauthorized`);
          } else {
            throw new Error(`${response.status} ${response.statusText}`);
          }
        }
        return response;
      }
      )
      .then(response => response.json())
      .then(json => {
        //console.log("json", json);
        dispatch(fetchKpiSuccess(json));
      })
      .catch((error: Error) => {
        dispatch(fetchKpiFaulure(error));
        autenticationTokenStorageService.logoutIfNotAuthorized(error);
      })
  };
}

