import * as React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import { AutenticationTokenStorageService } from '../_services/autentication.token.storage.service';
import { User } from '../_constants/auth-types';

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
}

const user:User = (new AutenticationTokenStorageService()).getLoggeUser();

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component,  ...rest } = props;

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                user ? (
                    <Component {...routeProps} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: routeProps.location }
                            }}
                        />
                    )
            }
        />
    );
};



/*import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AutenticationTokenStorageService } from '../_services/autentication.token.storage.service';
import { UserModel } from '../_models/user-model';

const user: UserModel = (new AutenticationTokenStorageService()).getLoggeUser();


export const PrivateRoute = ({ component: React.Component, ...rest }) => (
    <Route {...rest} render={props => (
        user
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
) */