export interface KpiModel {
    countProcessable: number;
    countProcessableCreatedFrom: number;

    countSendDelay: number;
    countUnProcessable: number;
    countSendingRequired: number;
    countSent: number;
    countFeedbackReceived: number;
    countSendingError: number;

    countReminderSent: number;
    countReminderFeedbackReceived: number;
    countReminderSendingError: number;

    unresetRecodedError: number;
    rejectedContact: number;
    rejectedContactHome: number;
    rejectedInstaller: number;
}

export interface SmsQueryModelForKpi  {
    olderThan: Date | undefined;
}

export interface KpiState {
    ageInHours: number;
    kpis: {
        item: KpiModel,
        loading: boolean
        error: Error
    }
}

export const FETCH_KPI_REQUEST = 'FETCH_KPI_REQUEST';
export const FETCH_KPI_SUCCESS = 'FETCH_KPI_SUCCESS';
export const FETCH_KPI_FAILURE = 'FETCH_KPI_FAILURE';

export interface FetchKpiRequestAction {
    type: typeof FETCH_KPI_REQUEST
    //payload: KpiModel
}
export interface FetchKpiSuccessAction {
    type: typeof FETCH_KPI_SUCCESS,
    payload: KpiModel
}
export interface FetchKpiFailureAction {
    type: typeof FETCH_KPI_FAILURE,
    payload: string
}

export type KpiActionTypes = FetchKpiRequestAction | FetchKpiSuccessAction | FetchKpiFailureAction
